// extracted by mini-css-extract-plugin
export var africarColor = "db_yN";
export var ammoColor = "db_yt";
export var babelColor = "db_yM";
export var cakerColor = "db_yx";
export var chatticColor = "db_yv";
export var danColor = "db_yw";
export var dumaColor = "db_yG";
export var dvColor = "db_yQ";
export var ifndColor = "db_ys";
export var impactColor = "db_yB";
export var ixColor = "db_yR";
export var malleniColor = "db_yz";
export var pmkColor = "db_yL";
export var refColor = "db_yH";
export var rewColor = "db_yJ";
export var timebotColor = "db_yD";
export var tradeaboatColor = "db_yF";
export var tradervsColor = "db_yC";
export var variaColor = "db_yy";
export var wownerColor = "db_yK";
export var xpcColor = "db_yP";